import React, { FC } from "react";
import { Page } from "../../layouts";
import { Accordion, Col, Container, ListGroup, Row } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./home.module.scss";

import bg from "./assets/bg.jpg";
import agro from "./assets/partner-agro.png";
import armar from "./assets/partner-armar.png";

interface Props {}

export const Home: FC<Props> = (props) => {
  return (
    <Page classNames={{ container: "pt-0" }}>
      <Container className={classes("position-relative", styles.welcome)} fluid>
        <img
          src={bg}
          className={classes("position-absolute top-0 start-0 w-100 h-100", styles.bg)}
          alt="background"
        />
        <Container className="position-relative h-100">
          <Row className="h-100">
            <Col xs={12} className="h-100 d-flex justify-content-center align-items-center">
              <h1 className="text-light text-uppercase text-center fs-1 my-0">
                Sperow - Робимо сільське господарство більш зрозумілим та надійним!
              </h1>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col xs={12}>
            <h3 className="text-uppercase my-0">Наші переваги</h3>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Доступність.</div>
                  <span>
                    Кожна людина може безкоштовно користуватись корисним базовим функціоналом.
                  </span>
                </div>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Системність.</div>
                  <span>
                    Плануйте та контролюйте всі процеси, краще ніж це роблять великі холдинги. Вся інформація
                    зберігається і аналізується системою.
                  </span>
                </div>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Економія часу.</div>
                  <span>
                    Наша платформа буде працювати на вас, економлячи ваш час. Буде нагадувати про важливе та
                    допоможе уникнути помилок.
                  </span>
                </div>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Розвиток.</div>
                  <span>
                    Знаходьте корисну інформацію та обмінюйтесь досвідом. Знаходьте вигідні партнерства та
                    розвивайте свій бізнес.
                  </span>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-5">
          <Col xs={12}>
            <h3 className="text-uppercase text-center my-0">
              Допомагаємо аграріям реалізовувати свій потенціал
            </h3>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <p className="my-0">
              Наша місія - зробити сільське господарство доступним та зрозумілим для кожного та надати можливості
              для розвитку малих та середніх аграріїв. Ми прагнемо, щоб кожна людина, яка працює на землі, могла
              скористатися нашими інструментами та ресурсами. Наша платформа призначена для аграріїв, і ми прагнемо
              дати можливість кожному фермеру використовувати передові технології. Вона дає можливість не лише
              зберегти існуючий бізнес, але й розвинути його. Фермери отримують доступ до якісних постачальників,
              знаходять досвідчених працівників та діляться своїм досвідом. Розуміючи складність та
              непередбачуваність аграрного сектора, ми працюємо, щоб зробити його більш передбачуваним та
              контрольованим.
            </p>
            <p className="mt-3">
              Наша платформа допомагає користувачам швидше знаходити допомогу та гранти, а також ми постійно
              розвиваємося, додаючи новий функціонал, щоб забезпечити фермерам доступ до передових технологій та
              штучного інтелекту. Ми прагнемо цифровізувати аграрний сектор та сприяти прямому спілкуванню між
              учасниками цієї екосистеми.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-5">
          <Col xs={12}>
            <h3 className="text-uppercase my-0">Наші цінності</h3>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <Accordion defaultActiveKey={[ "0" ]} alwaysOpen itemProp="erfeveverv">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1. Люди та комунікація</Accordion.Header>
                <Accordion.Body>
                  Найголовніше для успіху в роботі чому це якісна комунікація. Це може бути комунікація за
                  співробітниками або партнерами, покупцями чи продавцями. І від якості цієї комунікації залежить
                  результат роботи.Саме від людей залежить успіх роботи будь якого бізнесу.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2. Легкість та простота</Accordion.Header>
                <Accordion.Body>
                  Наша платформа створена таким чином що кожен зможе розібратись та навчитись нею користуватись.
                  Цьому сприяє інтуїтивно зрозумілий функціонал та сама платформа навчить вас як нею користуватись.
                  Аграрний бізнес і так дуже складний тому ми збираємося спростити його як тільки можливо.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3. Безпека та надійність</Accordion.Header>
                <Accordion.Body>
                  Це те чого не вистачає в нашій країні більш за все. Аграрії співпрацюють з військовими, але не
                  отримують нічого взамін. Це не кажучи про безпеку працівників, яких любого часу можуть мобілізувати.
                  А ще є внутрішня безпека з якою ми допомагаємо.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>4. Прозорість та контроль</Accordion.Header>
                <Accordion.Body>
                  В аграрному бізнесі дуже багато процесів відбуваються одночасно, є багато можливостей щось
                  приховати або обдурити. Ми ж хочемо уникнути цього і зробити відносини в аграрному секторі
                  більш чесними та прозорими.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>5. Розвиток та стабільність</Accordion.Header>
                <Accordion.Body>
                  Нам важливо щоб працівники розвивались та здобувати нові навички. Ті хто не розвиваються ті
                  вмирають. В сьогоднішніх умовах фермери не можуть розвиватись, вони думають як зберегти те
                  що мають. Ми розуміємо як важливо розвиватись і не стояти на місці. Тому в нас є власна стратегія
                  розвитку і ми будемо допомагати розвиватись всім користувачам нашої платформи.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>6. Стійкість та патріотизм</Accordion.Header>
                <Accordion.Body>
                  Ми працюємо з тими хто платить податки та працює на благо держави. Аграрії це ті хто тримають
                  Україну на плаву допомагають військовим. Ми створюємо виключно український продукт яким зможуть
                  користуватись в усьому світі.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>7. Довіра та відповідальність</Accordion.Header>
                <Accordion.Body>
                  Це найголовніше в аграрному бізнесі це те що напрацьовується роками роками. Це те що треба
                  заслужити. І те що не можна втрачати. Ми довіряємо нашим клієнтам та партнерам та хочемо того ж
                  самого від них. Чим відповідальніша людина тим більше їй можно довіряти і тим краще вона буде
                  виконувати свою роботу. В саме головне це відповідальність самих підприємців один перед одним.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>8. Екологія та гармонія</Accordion.Header>
                <Accordion.Body>
                  В аграрному секторі багато відходів природного и техногенного походження, які забруднюють
                  навколишнє середовище. За допомогою нашої платформи ми можемо збирати тару та відправляти на
                  переробку або підвищувати обсяги вирощування екологічної\органічної продукції.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-5">
          <Col xs={12}>
            <h3 className="text-center text-uppercase my-0">Партнери</h3>
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12} md={4} className="offset-md-1">
            <img src={armar} className="w-100" alt="armar" />
          </Col>
          <Col xs={12} md={4} className="offset-md-2 mt-5 mt-md-0">
            <img src={agro} className="w-100" alt="agro" />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};