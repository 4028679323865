import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApi, useAuthorization, useLoader, useNotification, useUser } from "../../hooks";
import { Page } from "../../layouts";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import Select from "react-select";
import { USER_GENDER, UserGender } from "../../models";
import { ERROR, errorBy, REGEXP } from "../../utils";
import { Controller, useForm } from "react-hook-form";
import { COUNTRIES, GENDER, INPUT_LENGTH, LANGUAGES, SELECT_STYLES } from "./sign-up.data";

interface Props {}

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  gender: "" | UserGender;
  dob: number;
  country: string;
  language: string;
  password: string;
  confirm: string;
  stay: boolean;
}

export const SignUp: FC<Props> = (props) => {
  const api = useApi();
  const navigate = useNavigate();
  const authorization  = useAuthorization();
  const user = useUser();
  const loader = useLoader();
  const notification = useNotification();

  const [ isVisiblePassword, setIsVisiblePassword ] = useState<boolean>(false);

  const {
    register,
    reset,
    control,
    formState: {
      errors,
      isValid,
    },
    setValue,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: "all" });

  const onSubmit = (data: FormData): void => {
    const task = loader.create("Зачекайте, будь ласка, обробляємо запит...");

    task.start();

    api.authorization.signUp({ data })
      .then(({ token, user: data }) => {
        authorization.setAccessToken(token);
        user.set(data);
        reset();
        navigate("/main");
        task.stop();
      })
      .catch(({ message, code }) => {
        if (code === ERROR.USER_ALREADY_EXISTS) {
          setError("email", { message: errorBy(code) });
        }

        if (code === ERROR.INVALID_PASSWORD) {
          setError("password", { message: errorBy(code) });
          setError("confirm", { message: errorBy(code) });
        }

        task.stop(() => {
          notification.error({ body: errorBy(code) || message });
        });
      });
  };

  return (
    <Page classNames={{ main: "justify-content-center align-items-center py-5" }}>
      <Container>
        <Row>
          <Col xs={12} lg={8} xl={6} className="offset-0 offset-lg-2 offset-xl-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12}>
                  <h1 className="fw-bold text-center my-0">Реєстрація</h1>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Ваш email: <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="E-mail..."
                      minLength={INPUT_LENGTH.EMAIL.MIN}
                      maxLength={INPUT_LENGTH.EMAIL.MAX}
                      className={!!errors?.email ? "border-danger" : ""}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Це поле є обов'язковим для введення.",
                        },
                        minLength: {
                          value: INPUT_LENGTH.EMAIL.MIN,
                          message: `Мінімальна довжина має становити ${INPUT_LENGTH.EMAIL.MIN} символів.`,
                        },
                        maxLength: {
                          value: INPUT_LENGTH.EMAIL.MAX,
                          message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.EMAIL.MAX} символів.`,
                        },
                        validate: (value) => !!value.match(REGEXP.EMAIL) || "Це не схоже на емейл.",
                      })}
                    />
                    {!!errors?.email ? (
                      <Form.Text className="text-danger">{errors.email.message}</Form.Text>
                    ) : (
                      <Form.Text className="text-muted">
                        Ми ніколи нікому не передамо вашу електронну адресу.
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label>Ім'я: <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ім'я..."
                      minLength={INPUT_LENGTH.FIRST_NAME.MIN}
                      maxLength={INPUT_LENGTH.FIRST_NAME.MAX}
                      className={!!errors?.firstName ? "border-danger" : ""}
                      {...register("firstName", {
                        required: {
                          value: true,
                          message: "Це поле є обов'язковим.",
                        },
                        minLength: {
                          value: INPUT_LENGTH.FIRST_NAME.MIN,
                          message: "Ім'я має бути довшим.",
                        },
                        maxLength: {
                          value: INPUT_LENGTH.FIRST_NAME.MAX,
                          message: "Ім'я має бути коротшим.",
                        },
                        validate: (value) => {
                          if (value.includes(" ")) {
                            return "Це поле не може містити пробілів.";
                          }
                        },
                      })}
                    />
                    {!!errors?.firstName ? (
                      <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>
                    ) : (
                      <Form.Text className="text-muted">Це поле є обов'язковим.</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                  <Form.Group controlId="formBasicLastName">
                    <Form.Label>Прізвище: <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Прізвище..."
                      minLength={INPUT_LENGTH.LAST_NAME.MIN}
                      maxLength={INPUT_LENGTH.LAST_NAME.MAX}
                      className={!!errors?.lastName ? "border-danger" : ""}
                      {...register("lastName", {
                        required: {
                          value: true,
                          message: "Це поле є обов'язковим.",
                        },
                        minLength: {
                          value: INPUT_LENGTH.LAST_NAME.MIN,
                          message: "Прізвище має бути довшим.",
                        },
                        maxLength: {
                          value: INPUT_LENGTH.LAST_NAME.MAX,
                          message: "Прізвище має бути коротшим.",
                        },
                        validate: (value) => {
                          if (value.includes(" ")) {
                            return "Це поле не може містити пробілів.";
                          }
                        },
                      })}
                    />
                    {!!errors?.lastName ? (
                      <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>
                    ) : (
                      <Form.Text className="text-muted">Це поле є обов'язковим.</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Group controlId="formBasicPhone">
                    <Form.Label>Ваш телефон:</Form.Label>
                    <Form.Control type="text" placeholder="Телефон..." {...register("phone")} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Group controlId="formBasicGender">
                    <Form.Label>Стать:</Form.Label>
                    <Controller
                      control={control}
                      name="gender"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Виберіть стать..."
                          value={!!field.value ? { value: field.value, label: GENDER[field.value] } : undefined}
                          options={Object.values(USER_GENDER).map((item) => ({ value: item, label: GENDER[item] }))}
                          // @ts-ignore
                          onChange={(newValue) => setValue("gender", newValue.value)}
                          styles={SELECT_STYLES}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <Form.Group
                    controlId="formBasicCountry"
                    className="d-flex flex-column justify-content-start align-items-stretch"
                  >
                    <Form.Label>Країна: <span className="text-danger">*</span></Form.Label>
                    <Controller
                      control={control}
                      name="country"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Виберіть країну..."
                          value={!!field.value ?
                            {
                              value: field.value,
                              label: COUNTRIES.find(({ value }) => value === field.value)?.label,
                            } : undefined
                          }
                          options={COUNTRIES}
                          // @ts-ignore
                          onChange={(newValue) => setValue("country", newValue.value)}
                          styles={SELECT_STYLES}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                  <Form.Group
                    controlId="formBasicLanguage"
                    className="d-flex flex-column justify-content-start align-items-stretch"
                  >
                    <Form.Label>Мова: <span className="text-danger">*</span></Form.Label>
                    <Controller
                      control={control}
                      name="language"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Виберіть мову..."
                          value={!!field.value ?
                            {
                              value: field.value,
                              label: LANGUAGES.find(({ value }) => value === field.value)?.label,
                            } : undefined
                          }
                          options={LANGUAGES}
                          // @ts-ignore
                          onChange={(newValue) => setValue("language", newValue.value)}
                          styles={SELECT_STYLES}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Ваш пароль: <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type={isVisiblePassword ? "text" : "password"}
                      placeholder="Пароль..."
                      minLength={INPUT_LENGTH.PASSWORD.MIN}
                      maxLength={INPUT_LENGTH.PASSWORD.MAX}
                      className={!!errors?.password ? "border-danger" : ""}
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Це поле є обов'язковим для введення.",
                        },
                        minLength: {
                          value: INPUT_LENGTH.PASSWORD.MIN,
                          message: `Мінімальна довжина має становити ${INPUT_LENGTH.PASSWORD.MIN} символів.`,
                        },
                        maxLength: {
                          value: INPUT_LENGTH.PASSWORD.MAX,
                          message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.PASSWORD.MAX} символів.`,
                        },
                        validate: (value, { confirm }) => {
                          if (
                            value.length >= INPUT_LENGTH.PASSWORD.MIN
                            && value.length <= INPUT_LENGTH.PASSWORD.MAX
                            && value.trim().length < INPUT_LENGTH.PASSWORD.MIN
                          ) {
                            return "Пароль не може складатись з пробілів.";
                          }

                          if (
                            value.length >= INPUT_LENGTH.PASSWORD.MIN
                            && confirm.length >= INPUT_LENGTH.PASSWORD.MIN
                            && value !== confirm
                          ) {
                            setError("confirm", { type: "validate", message: "Паролі не співпадають." });

                            return "Паролі не співпадають.";
                          }

                          if (value.length === confirm.length && value === confirm) {
                            clearErrors("confirm");

                            return true;
                          }
                        }
                      })}
                    />
                    {!!errors?.password ? (
                      <Form.Text className="text-danger">{errors.password.message}</Form.Text>
                    ) : (
                      <Form.Text className="text-muted">Ми та ніхто інший ніколи не дізнається ваш пароль.</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Group controlId="formBasicConfirm">
                    <Form.Label>Підтвердіть пароль: <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type={isVisiblePassword ? "text" : "password"}
                      placeholder="Підтвердіть пароль..."
                      minLength={INPUT_LENGTH.PASSWORD.MIN}
                      maxLength={INPUT_LENGTH.PASSWORD.MAX}
                      className={!!errors?.confirm ? "border-danger" : ""}
                      {...register("confirm", {
                        required: {
                          value: true,
                          message: "Це поле є обов'язковим для введення.",
                        },
                        minLength: {
                          value: INPUT_LENGTH.PASSWORD.MIN,
                          message: `Мінімальна довжина має становити ${INPUT_LENGTH.PASSWORD.MIN} символів.`,
                        },
                        maxLength: {
                          value: INPUT_LENGTH.PASSWORD.MAX,
                          message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.PASSWORD.MAX} символів.`,
                        },
                        validate: (value, { password }) => {
                          if (
                            value.length >= INPUT_LENGTH.PASSWORD.MIN
                            && value.length <= INPUT_LENGTH.PASSWORD.MAX
                            && value.trim().length < INPUT_LENGTH.PASSWORD.MIN
                          ) {
                            return "Пароль не може складатись з пробілів.";
                          }

                          if (
                            value.length >= INPUT_LENGTH.PASSWORD.MIN
                            && password.length >= INPUT_LENGTH.PASSWORD.MIN
                            && value !== password
                          ) {
                            setError("password", { type: "validate", message: "Паролі не співпадають." });

                            return "Паролі не співпадають.";
                          }

                          if (value.length === password.length && value === password) {
                            clearErrors("password");

                            return true;
                          }
                        }
                      })}
                    />
                    {!!errors?.confirm ? (
                      <Form.Text className="text-danger">{errors.confirm.message}</Form.Text>
                    ) : (
                      <Form.Text className="text-muted">Ми та ніхто інший ніколи не дізнається ваш пароль.</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Check
                    type="checkbox"
                    id="sign-up_visible-password"
                    label="Показати пароль"
                    onChange={() => setIsVisiblePassword((prevState) => !prevState)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Form.Check type="checkbox" id="sign-up_stay" label="Залишатись в системі" {...register("stay")} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Stack direction="vertical" className="justify-content-center align-items-center gap-3">
                    <Button variant="primary" type="submit" disabled={!isValid}>Зареєструватись</Button>
                    <span className="fs-6 text-center text-secondary">
                      У вас вже є обліковий запис? Спробуйте <Link to="/sign-in">авторизуватись</Link>!
                    </span>
                  </Stack>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};