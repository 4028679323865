// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_notification__HRClH {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
}

.notification_icon__DekUx {
  width: 16px;
  height: 16px;
}

.notification_body__kWA4n {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/notification/notification.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AACF","sourcesContent":[".notification {\n  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);\n}\n\n.icon {\n  width: 16px;\n  height: 16px;\n}\n\n.body {\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `notification_notification__HRClH`,
	"icon": `notification_icon__DekUx`,
	"body": `notification_body__kWA4n`
};
export default ___CSS_LOADER_EXPORT___;
