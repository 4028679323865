import React from "react";
import ReactDOM from "react-dom/client";
import { Root } from "./components";

import "./styles/bootstrap.scss";
import "./styles/global.scss";

const node = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

node.render(<Root />);
