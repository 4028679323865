import React, { FC, useEffect, useState } from "react";
import { Page } from "../../layouts";
import { EnterCodeForm } from "../../forms";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useApi, useLoader, useNotification, useUser } from "../../hooks";
import { errorBy } from "../../utils";

interface Props {}

const TIME_INTERVAL = 60;

export const Activation: FC<Props> = (props) => {
  const api = useApi();
  const user = useUser();
  const loader = useLoader();
  const notification = useNotification();

  const [ counter, setCounter ] = useState<number>(0);

  useEffect(() => {
    if (counter > 0 && counter <= TIME_INTERVAL) {
      const timeout = setTimeout(() => {
        setCounter((prevState) => prevState - 1);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [ counter ]);

  const onSubmit = (code: string): void => {
    const task = loader.create("Пробуємо активувати обліковий запис...");

    task.start();

    api.user.activate({ data: { code } })
      .then((data) => {
        user.set(data);
        task.stop();
      })
      .catch(({ message, code }) => {
        task.stop(() => {
          notification.error({ body: errorBy(code) || message });
        });
      });
  };

  const handleResendActivationMail = (): void => {
    const task = loader.create("Надсилаємо лист...");

    task.start();

    api.user.sendActivationMail({})
      .then(() => {
        setCounter(TIME_INTERVAL);
        task.stop();
      })
      .catch(() => {
        task.stop();
      });
  };

  return (
    <Page classNames={{ main: "d-flex flex-column justify-content-center align-items-stretch py-5" }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h1 className="text-center my-0">Ласкаво просимо до Sperow!</h1>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} lg={8} className="offset-0 offset-lg-2">
            <span className="d-inline-block text-center text-secondary">
              Для подальшої роботи нам необхідно підтвердити адресу електронної пошти. Вам було надіслано
              листа з кодом на <span className="text-primary fw-bold">{user.email}</span>.
            </span>
          </Col>
        </Row>
        <Row className="mt-3">
          <EnterCodeForm onSubmit={onSubmit} />
        </Row>
        <Row className="mt-3">
          <Col xs={12} lg={8} className="offset-0 offset-lg-2">
            <span className="d-inline-block text-center text-secondary">
              Якщо ви не знайшли листа, цілком можливо, що лист знаходиться в папці спам. Ви також можете&nbsp;
              {counter > 0 && "відправити лист з кодом заново"}
              {counter === 0 && (
                <Button
                  variant="link"
                  className="align-baseline p-0"
                  onClick={handleResendActivationMail}
                  disabled={counter > 0}
                >відправити лист з кодом заново</Button>
              )}
              {counter > 0 && " через " + counter + " секунд"}.
            </span>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};