// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_container__QbKC- {
  height: 200px;
}

.footer_label__vH6yU {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".container {\n  height: 200px;\n}\n\n.label {\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `footer_container__QbKC-`,
	"label": `footer_label__vH6yU`
};
export default ___CSS_LOADER_EXPORT___;
