import React, { FC, PropsWithChildren, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./dashboard.module.scss";

interface Props extends PropsWithChildren {
  classNames?: {
    container?: string;
    sidebar?: string;
    header?: string;
    body?: string;
    breadcrumbs?: string;
    main?: string;
  };
}

export const Dashboard: FC<Props> = ({ classNames, children }) => {
  const [ isExpanded, setIsExpanded ] = useState<boolean>(true);

  return (
    <Stack
      direction="horizontal"
      className={classes(
        "position-relative align-items-stretch",
        styles.container,
        isExpanded && styles.expanded,
        classNames?.container,
      )}
    >
      <aside
        className={classes(
          "position-fixed top-0 start-0 d-flex justify-content-center align-items-center bg-primary z-2",
          styles.sidebar,
          classNames?.sidebar,
        )}
      >
        <span className="text-light">SIDEBAR</span>
      </aside>
      <Stack direction="vertical" className={classes("position-relative align-items-stretch", classNames?.body)}>
        <header
          className={classes(
            "position-fixed top-0 end-0 d-flex justify-content-center align-items-center bg-light border-bottom z-1",
            styles.header,
            classNames?.header,
          )}
        >
          <Container fluid>
            <Stack direction="horizontal" className="justify-content-between align-items-center">
              <div
                role="button"
                className={classes("border", styles.back)}
                onClick={() => setIsExpanded((prevState) => !prevState)}
              />
              <Stack direction="horizontal" className="align-items-center gap-2">
                <div className="link">Link 1</div>
                <div className="link">Link 2</div>
                <div className="link">Link 3</div>
              </Stack>
            </Stack>
          </Container>
        </header>
        <main
          className={classes(
            "position-fixed bottom-0 end-0 d-flex flex-column align-items-stretch z-0",
            styles.main,
          )}
        >
          <nav className={classes("d-flex align-items-center py-2", styles.breadcrumbs, classNames?.breadcrumbs)}>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <span className="text-primary">Головна / Агрономия</span>
                </Col>
              </Row>
            </Container>
          </nav>
          <div className={classNames?.main}>{children}</div>
        </main>
      </Stack>
    </Stack>
  );
};