import React, { FC, useState } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Page } from "../../layouts";
import { EnterCodeForm } from "../../forms";
import { useApi, useLoader, useNotification } from "../../hooks";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ERROR, errorBy, REGEXP } from "../../utils";
import { INPUT_LENGTH } from "./reset-password.data";

interface Props {}

interface FormEmailData {
  email: string;
}

interface FormPasswordData {
  password: string;
  confirm: string;
}

export const ResetPassword: FC<Props> = (props) => {
  const api = useApi();
  const navigate = useNavigate();
  const loader = useLoader();
  const notification = useNotification();

  const [ stage, setStage ] = useState<"email" | "code" | "password">("email");
  const [ code, setCode ] = useState<string>("");
  const [ isVisiblePassword, setIsVisiblePassword ] = useState<boolean>(false);

  const emailForm = useForm<FormEmailData>({ mode: "all" });
  const passwordForm = useForm<FormPasswordData>({ mode: "all" });

  const onSubmitEmail = (data: FormEmailData): void => {
    const task = loader.create("Зачекайте, будь ласка, обробляємо запит...");

    task.start();

    api.user.sendResetPasswordMail({ data })
      .then(() => {
        task.stop();
        setStage("code");
      })
      .catch(({ message, code }) => {
        task.stop(() => {
          notification.error({ body: errorBy(code) || message });
        });
      });
  };

  const onSubmitCode = (): void => {
    const task = loader.create("Зачекайте, будь ласка, обробляємо запит...");

    task.start();

    api.user.resetPasswordVerify({ data: { email: emailForm.getValues("email"), code } })
      .then(() => {
        setStage("password");
        task.stop(() => {
          notification.success({ body: "Код успішно підтверджено!" });
        });
      })
      .catch(({ message, code }) => {
        task.stop(() => {
          notification.error({ body: errorBy(code) || message });
        });
      });
  };

  const onSubmitPassword = (data: FormPasswordData): void => {
    const task = loader.create("Зачекайте, будь ласка, обробляємо запит...");

    task.start();

    api.user.resetPassword({ data: { email: emailForm.getValues("email"), password: data.password, code } })
      .then(() => {
        navigate("/sign-in");
        task.stop(() => {
          notification.success({ body: "Ваш пароль успішно змінено!" });
        });
      })
      .catch(({ message, code }) => {
        if (code === ERROR.INVALID_PASSWORD) {
          passwordForm.setError("password", { message: errorBy(code) });
          passwordForm.setError("confirm", { message: errorBy(code) });
        }

        task.stop(() => {
          notification.error({ body: errorBy(code) || message });
        });
      });
  };

  return (
    <Page classNames={{ main: "justify-content-center align-items-center py-5" }}>
      <Container>
        <Row>
          <Col
            xs={12}
            sm={10}
            md={8}
            lg={6}
            xxl={4}
            className="offset-0 offset-sm-1 offset-md-2 offset-lg-3 offset-xxl-4"
          >
            <h1 className="fw-bold text-center my-0">Відновлення доступу</h1>
            {stage === "email" && (
              <Form className="mt-3" onSubmit={emailForm.handleSubmit(onSubmitEmail)}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Ваш email:</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail..."
                    minLength={INPUT_LENGTH.EMAIL.MIN}
                    maxLength={INPUT_LENGTH.EMAIL.MAX}
                    className={!!emailForm.formState.errors?.email ? "border-danger" : ""}
                    {...emailForm.register("email", {
                      required: {
                        value: true,
                        message: "Це поле є обов'язковим для введення.",
                      },
                      minLength: {
                        value: INPUT_LENGTH.EMAIL.MIN,
                        message: `Мінімальна довжина має становити ${INPUT_LENGTH.EMAIL.MIN} символів.`,
                      },
                      maxLength: {
                        value: INPUT_LENGTH.EMAIL.MAX,
                        message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.EMAIL.MAX} символів.`,
                      },
                      validate: (value) => !!value.match(REGEXP.EMAIL) || "Це не схоже на емейл.",
                    })}
                  />
                  {!!emailForm.formState.errors?.email ? (
                    <Form.Text className="text-danger">{emailForm.formState.errors.email.message}</Form.Text>
                  ) : (
                    <Form.Text className="text-muted">Для відновлення доступу ми надішлемо вам лист.</Form.Text>
                  )}
                </Form.Group>
                <Stack direction="horizontal" className="justify-content-center mt-3">
                  <Button variant="primary" type="submit" disabled={!emailForm.formState.isValid}>
                    Надіслати лист
                  </Button>
                </Stack>
              </Form>
            )}
            {stage === "code" && (
              <Stack direction="vertical" className="mt-3">
                <span className="d-inline-block text-center text-secondary">
                  Для подальшої роботи нам необхідно впевнитись що цей аккаунт належить саме вам. Вам було надіслано
                  листа з кодом на <span className="text-primary fw-bold">{emailForm.getValues("email")}</span>.
                </span>
                <EnterCodeForm
                  className="mt-3"
                  initialState={code}
                  onInit={(list) => {
                    if (!!code && code.length === 6) {
                      list[5].ref.current?.focus();
                    }
                  }}
                  onChange={(value) => setCode(value)}
                  onSubmit={onSubmitCode}
                />
              </Stack>
            )}
            {stage === "password" && (
              <Form className="mt-3" onSubmit={passwordForm.handleSubmit(onSubmitPassword)}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Новий пароль: <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type={isVisiblePassword ? "text" : "password"}
                    placeholder="Новий пароль..."
                    minLength={INPUT_LENGTH.PASSWORD.MIN}
                    maxLength={INPUT_LENGTH.PASSWORD.MAX}
                    className={!!passwordForm.formState.errors?.password ? "border-danger" : ""}
                    {...passwordForm.register("password", {
                      required: {
                        value: true,
                        message: "Це поле є обов'язковим для введення.",
                      },
                      minLength: {
                        value: INPUT_LENGTH.PASSWORD.MIN,
                        message: `Мінімальна довжина має становити ${INPUT_LENGTH.PASSWORD.MIN} символів.`,
                      },
                      maxLength: {
                        value: INPUT_LENGTH.PASSWORD.MAX,
                        message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.PASSWORD.MAX} символів.`,
                      },
                      validate: (value, { confirm }) => {
                        if (
                          value.length >= INPUT_LENGTH.PASSWORD.MIN
                          && value.length <= INPUT_LENGTH.PASSWORD.MAX
                          && value.trim().length < INPUT_LENGTH.PASSWORD.MIN
                        ) {
                          return "Пароль не може складатись з пробілів.";
                        }

                        if (
                          value.length >= INPUT_LENGTH.PASSWORD.MIN
                          && confirm.length >= INPUT_LENGTH.PASSWORD.MIN
                          && value !== confirm
                        ) {
                          passwordForm.setError("confirm", { type: "validate", message: "Паролі не співпадають." });

                          return "Паролі не співпадають.";
                        }

                        if (value.length === confirm.length && value === confirm) {
                          passwordForm.clearErrors("confirm");

                          return true;
                        }
                      },
                    })}
                  />
                  {!!passwordForm.formState.errors?.password ? (
                    <Form.Text className="text-danger">{passwordForm.formState.errors.password.message}</Form.Text>
                  ) : (
                    <Form.Text className="text-muted">Ми та ніхто інший ніколи не дізнається ваш пароль.</Form.Text>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicConfirm">
                  <Form.Label>Підтвердіть пароль: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type={isVisiblePassword ? "text" : "password"}
                    placeholder="Підтвердіть пароль..."
                    minLength={INPUT_LENGTH.PASSWORD.MIN}
                    maxLength={INPUT_LENGTH.PASSWORD.MAX}
                    className={!!passwordForm.formState.errors?.confirm ? "border-danger" : ""}
                    {...passwordForm.register("confirm", {
                      required: {
                        value: true,
                        message: "Це поле є обов'язковим для введення.",
                      },
                      minLength: {
                        value: INPUT_LENGTH.PASSWORD.MIN,
                        message: `Мінімальна довжина має становити ${INPUT_LENGTH.PASSWORD.MIN} символів.`,
                      },
                      maxLength: {
                        value: INPUT_LENGTH.PASSWORD.MAX,
                        message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.PASSWORD.MAX} символів.`,
                      },
                      validate: (value, { password }) => {
                        if (
                          value.length >= INPUT_LENGTH.PASSWORD.MIN
                          && value.length <= INPUT_LENGTH.PASSWORD.MAX
                          && value.trim().length < INPUT_LENGTH.PASSWORD.MIN
                        ) {
                          return "Пароль не може складатись з пробілів.";
                        }

                        if (
                          value.length >= INPUT_LENGTH.PASSWORD.MIN
                          && password.length >= INPUT_LENGTH.PASSWORD.MIN
                          && value !== password
                        ) {
                          passwordForm.setError("password", { type: "validate", message: "Паролі не співпадають." });

                          return "Паролі не співпадають.";
                        }

                        if (value.length === password.length && value === password) {
                          passwordForm.clearErrors("password");

                          return true;
                        }
                      },
                    })}
                  />
                  {!!passwordForm.formState.errors?.confirm ? (
                    <Form.Text className="text-danger">{passwordForm.formState.errors.confirm.message}</Form.Text>
                  ) : (
                    <Form.Text className="text-muted">Ми та ніхто інший ніколи не дізнається ваш пароль.</Form.Text>
                  )}
                </Form.Group>
                <Form.Check
                  type="checkbox"
                  id="sign-up_visible-password"
                  label="Показати пароль"
                  className="mt-3"
                  onChange={() => setIsVisiblePassword((prevState) => !prevState)}
                />
                <Stack direction="horizontal" className="justify-content-center mt-3">
                  <Button variant="primary" type="submit" disabled={!passwordForm.formState.isValid}>
                    Змінити пароль
                  </Button>
                </Stack>
              </Form>
            )}
            <span className="w-100 d-inline-block fs-6 text-center text-secondary mt-3">
              У вас немає облікового запису? Спробуйте <Link to="/sign-up">зареєструватися</Link>!
            </span>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};