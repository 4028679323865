import React, { FC } from "react";
import { classes } from "../../utils";
import { Col, Container, Row, Stack } from "react-bootstrap";
import styles from "./footer.module.scss";

interface Props {
  className?: string;
}

export const Footer: FC<Props> = ({ className }) => {
  const year: number = (new Date()).getFullYear();

  return (
    <footer className={classes("w-100 bg-primary", styles.container, className)}>
      <Container className="h-100">
        <Row className="h-100">
          <Col xs={12} className="h-100">
            <Stack className="h-100 justify-content-center align-items-center">
              <span className={classes("text-secondary", styles.label)}>© {year}. All rights reserved.</span>
            </Stack>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};