export const ERROR = {
  NETWORK: "network_error",
  BAD_REQUEST: "bad_request_error",
  NOT_AUTHORIZED: "not_authorized_error",
  INVALID_ARGUMENT: "invalid_argument_error",
  INVALID_PASSWORD: "invalid_password_error",
  USER_NOT_FOUND: "user_not_found_error",
  INVALID_TOKEN: "invalid_token_error",
  TOKEN_EXPIRED: "token_expired_error",
  USER_ALREADY_EXISTS: "user_already_exists_error",
  INVALID_CODE: "invalid_code_error",
};

const TRANSLATE_ERROR = {
  [ERROR.NETWORK]: "Проблеми з підключенням.",
  [ERROR.BAD_REQUEST]: "Помилка запита.",
  [ERROR.NOT_AUTHORIZED]: "Потрібна авторизація.",
  [ERROR.INVALID_ARGUMENT]: "Недійсний аргумент.",
  [ERROR.INVALID_PASSWORD]: "Невірний пароль.",
  [ERROR.USER_NOT_FOUND]: "Користувача з таким email не знайдено.",
  [ERROR.INVALID_TOKEN]: "Не валідний токен авторизації.",
  [ERROR.TOKEN_EXPIRED]: "Термін придатності токена авторизації минув.",
  [ERROR.USER_ALREADY_EXISTS]: "Користувач з таким email вже існує.",
  [ERROR.INVALID_CODE]: "Не вірний код, перевірте правильність введеного коду та спробуйте ще раз.",
};

export const errorBy = (code: string) => {
  return TRANSLATE_ERROR[code];
};