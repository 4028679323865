import React, { FC } from "react";
import { Badge, Container, Dropdown, DropdownDivider, DropdownToggle, Stack } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuthorization, useLoader, useUser } from "../../hooks";
import { classes } from "../../utils";
import logo from "./assets/logo.png";
import styles from "./header.module.scss";

interface Props {
  className?: string;
}

export const Header: FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const loader = useLoader();
  const authorization = useAuthorization();
  const user = useUser();

  const handleSelect = (key: null | string): void => {
    if (!key) {
      return;
    }

    switch (key) {
      case "main":
        navigate("/main");

        break;
      case "plan":
        navigate("/pricing");

        break;
      case "dashboard":
        navigate("/dashboard");

        break;
      case "sign-out":
        const task = loader.create("Вихід з системи...");

        task.start();

        const timeout = setTimeout(() => {
          authorization.resetAccessToken();
          user.reset();
          navigate("/");
          task.stop();
          clearTimeout(timeout);
        }, 1000);

        break;
    }
  };

  return (
    <header className={classes("w-100 bg-primary", styles.container, className)}>
      <Container className="h-100">
        <Stack direction="horizontal" className="h-100 justify-content-between align-items-center">
          <Link to="/" className="text-decoration-none border border-secondary rounded-3">
            <img src={logo} className={styles.logo} alt="logo" />
          </Link>
          <Stack direction="horizontal" className="gap-2">
            {!authorization.isAuthorized && (
              <NavLink
                to="/sign-in"
                className={({ isActive }) => {
                  return classes("text-decoration-none me-2", styles.link, isActive && "text-warning");
                }
              }>Увійти</NavLink>
            )}
            <NavLink
              to="/pricing"
              className={({ isActive }) => {
                return classes("text-decoration-none me-2", styles.link, isActive && "text-warning");
              }}
            >Ціни</NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) => {
                return classes("text-decoration-none me-2", styles.link, isActive && "text-warning")
              }
            }>Про нас</NavLink>
            {authorization.isAuthorized && (
              <Dropdown align="end" onSelect={(eventKey, event) => handleSelect(eventKey)}>
                <DropdownToggle variant="primary" className="d-flex justify-content-start align-items-center gap-2">
                  <span>{user.firstName} {user.lastName[0]}.</span>
                </DropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item as="div" eventKey="main">
                    <span className="text-dark">Головна</span>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" eventKey="plan">
                    <span className="text-dark">
                      Підписки<Badge bg="warning" className="ms-2">FREE</Badge>
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" eventKey="dashboard">
                    <span className="text-dark">Дашборд</span>
                  </Dropdown.Item>
                  <DropdownDivider />
                  <Dropdown.Item as="div" eventKey="sign-out">
                    <span className="text-dark">Вийти</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Stack>
        </Stack>
      </Container>
    </header>
  );
};