// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enter-code-form_input__Hi2xX {
  width: 50px;
  height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/forms/enter-code-form/enter-code-form.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".input {\n  width: 50px;\n  height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `enter-code-form_input__Hi2xX`
};
export default ___CSS_LOADER_EXPORT___;
