import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserGender, UserRole } from "../../models";

export interface UserState extends User {}

const initialState: UserState = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  fullName: "",
  role: "USER",
  phone: "",
  gender: "",
  dob: 0,
  customerId: "",
  isActivated: false,
  isBanned: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.fullName = action.payload.fullName;
      state.role = action.payload.role;
      state.phone = action.payload.phone;
      state.gender = action.payload.gender;
      state.dob = action.payload.dob;
      state.customerId = action.payload.customerId;
      state.isActivated = action.payload.isActivated;
      state.isBanned = action.payload.isBanned;
    },
    resetUser: (state) => {
      state.id = initialState.id;
      state.email = initialState.email;
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
      state.fullName = initialState.fullName;
      state.role = initialState.role;
      state.phone = initialState.phone;
      state.gender = initialState.gender;
      state.dob = initialState.dob;
      state.customerId = initialState.customerId;
      state.isActivated = initialState.isActivated;
      state.isBanned = initialState.isBanned;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setUserLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setUserFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.role = action.payload;
    },
    setUserPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setUserGender: (state, action: PayloadAction<"" | UserGender>) => {
      state.gender = action.payload;
    },
    setUserDob: (state, action: PayloadAction<number>) => {
      state.dob = action.payload;
    },
    setUserCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
    setUserIsActivated: (state, action: PayloadAction<boolean>) => {
      state.isActivated = action.payload;
    },
    setUserIsBanned: (state, action: PayloadAction<boolean>) => {
      state.isBanned = action.payload;
    },
  },
});

export const {
  reducer: userReducer,
  actions: {
    setUser,
    resetUser,
    setUserId,
    setUserEmail,
    setUserFirstName,
    setUserLastName,
    setUserFullName,
    setUserRole,
    setUserPhone,
    setUserGender,
    setUserDob,
    setUserCustomerId,
    setUserIsActivated,
    setUserIsBanned,
  },
} = userSlice;