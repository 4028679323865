import React, { FC, useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./notification.module.scss";

import message from "./assets/message.svg";
import success from "./assets/success.svg";
import info from "./assets/info.svg";
import warning from "./assets/warning.svg";
import error from "./assets/error.svg";

interface Props {
  type: "message" | "success" | "info" | "warning" | "error";
  title?: string;
  body?: React.ReactNode;
  onClose?: () => void;
}

const getStatusBySeconds = (seconds: number, ts: number) => {
  if (seconds < 60) {
    return "щойно";
  }

  if (Math.floor(seconds / 60) < 60) {
    return `${Math.floor(seconds / 60)}хв. тому`;
  }

  const date = new Date(ts);

  return `${date.toLocaleString("en-US", { hour12: false, hour: "2-digit", minute: "2-digit" })}`;
};

export const Notification: FC<Props> = ({ type, title, body, onClose }) => {
  const [ ts ] = useState<number>(Date.now());
  const [ seconds, setSeconds ] = useState<number>((Math.floor(Date.now() / 1000) - Math.floor(ts / 1000)));

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(Math.floor(Date.now() / 1000) - Math.floor(ts / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Toast className={classes("border-0", styles.notification)} onClose={onClose} animation>
      <Toast.Header className="bg-primary" closeVariant="white">
        {type === "message" && (
          <React.Fragment>
            <img src={message} alt="" />
            <strong className="text-light ms-2 me-auto">{title === undefined ? "Повідомлення" : title}</strong>
          </React.Fragment>
        )}
        {type === "success" && (
          <React.Fragment>
            <img src={success} alt="" />
            <strong className="text-light ms-2 me-auto">{title === undefined ? "Успіх" : title}</strong>
          </React.Fragment>
        )}
        {type === "info" && (
          <React.Fragment>
            <img src={info} alt="" />
            <strong className="text-light ms-2 me-auto">{title === undefined ? "Цікаво" : title}</strong>
          </React.Fragment>
        )}
        {type === "warning" && (
          <React.Fragment>
            <img src={warning} alt="" />
            <strong className="text-light ms-2 me-auto">{title === undefined ? "Увага" : title}</strong>
          </React.Fragment>
        )}
        {type === "error" && (
          <React.Fragment>
            <img src={error} alt="" />
            <strong className="text-light ms-2 me-auto">{title === undefined ? "Помилка" : title}</strong>
          </React.Fragment>
        )}
        <small className="text-warning">{getStatusBySeconds(seconds, ts)}</small>
      </Toast.Header>
      <Toast.Body>
        {typeof body === "string" ? <span className={styles.body}>{body}</span> : body}
      </Toast.Body>
    </Toast>
  );
};