// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* COLORS */
.pricing_card__Z0f8t {
  transition: box-shadow ease 200ms;
}
.pricing_card__Z0f8t:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow ease 200ms;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss","webpack://./src/pages/pricing/pricing.module.scss"],"names":[],"mappings":"AAIA,WAAA;ACFA;EACE,iCAAA;AAAF;AAEE;EACE,yCAAA;EACA,iCAAA;AAAJ","sourcesContent":["@import \"~bootstrap/scss/functions\";\n@import \"~bootstrap/scss/variables\";\n@import \"~bootstrap/scss/mixins\";\n\n/* COLORS */\n$primary: #053129;\n$warning: #ffbC36;","@import \"../../styles/variables\";\n\n.card {\n  transition: box-shadow ease 200ms;\n\n  &:hover {\n    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);\n    transition: box-shadow ease 200ms;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `pricing_card__Z0f8t`
};
export default ___CSS_LOADER_EXPORT___;
