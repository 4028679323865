export type UserRole = "USER" | "ADMIN";

export const USER_ROLE: { USER: UserRole, ADMIN: UserRole } = {
  USER: "USER",
  ADMIN: "ADMIN",
};

export type UserGender = "MALE" | "FEMALE" | "OTHER";

export const USER_GENDER: { MALE: UserGender, FEMALE: UserGender, OTHER: UserGender } = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
};

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  role: UserRole;
  phone: string;
  gender: "" | UserGender;
  dob: number;
  customerId: string;
  isActivated: boolean;
  isBanned: boolean;
}