import React, { FC } from "react";
import { useAuthorization } from "../hooks";
import { NotAuthorized } from "../pages";

export const withRequireAuthorization = (Component: FC): FC => {
  return (props) => {
    const { isAuthorized } = useAuthorization();

    if (!isAuthorized) {
      return <NotAuthorized />;
    }

    return <Component {...props} />
  };
};