import { CSSObjectWithLabel } from "react-select";

export const INPUT_LENGTH = {
  EMAIL: {
    MIN: 5,
    MAX: 50,
  },
  FIRST_NAME: {
    MIN: 2,
    MAX: 32,
  },
  LAST_NAME: {
    MIN: 2,
    MAX: 32,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 32,
  },
};

export const SELECT_STYLES = {
  control: (baseStyles: CSSObjectWithLabel, state: any) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#053129" : "#dee2e6",
    boxShadow: "none",

    ":hover": {
      borderColor: state.isFocused ? "#053129" : "#dee2e6",
    },
  }),
  option: (base: CSSObjectWithLabel, props: any) => ({
    ...base,
    backgroundColor: props.isSelected ? "#053129" : "",
    cursor: "pointer",

    ":active": {
      backgroundColor: props.isSelected ? "#053129" : "#ffbC36",
    },
  }),
};

export const GENDER: { [key: string]: string } = {
  MALE: "Чоловік",
  FEMALE: "Жінка",
  OTHER: "Інше",
};

export const COUNTRIES = [
  { value: "uk", label: "Ukraine" },
  { value: "us", label: "USA" },
];

export const LANGUAGES = [
  { value: "uk", label: "Ukrainian" },
  { value: "en", label: "English" },
];