export const PARAGRAPHS: Array<{ key: string, text: string }> = [
  {
    key: "1",
    text: "SPEROW - це екосистема, яка присвячена підтримці малих та середніх фермерів, а також спрощенню їхньої роботи. Наша місія - зробити сільське господарство доступним та зрозумілим для кожного, надаючи інструменти та ресурси для розвитку аграріїв будь-якого розміру. Сільське господарство - це одна з основних галузей економіки, і ми розуміємо, що вона стикається з численними викликами і проблемами. Наша платформа спрямована на забезпечення рішень для цих проблем та допомогу фермерам у вирішенні нагальних завдань.",
  },
  {
    key: "2",
    text: "У сучасному світі сільське господарство стикається з різними складнощами, включаючи складне управління, недостатню комунікацію, проблеми з ефективністю та збереженням даних. SPEROW - це ваш помічник, призначений для розв'язання цих проблем, надаючи фермерам інструменти для управління фермою, сприяючи обміну інформацією та досвідом між учасниками аграрного ринку та допомагаючи зберігати та аналізувати дані для прийняття рішень.",
  },
  {
    key: "3",
    text: "Наша платформа розроблена з урахуванням потреб малих та середніх фермерів, і ми прагнемо зробити сільське господарство більш доступним та ефективним для кожного. Ми віримо, що кожен фермер має право на доступ до передових технологій та ресурсів, які допоможуть йому управляти своєю фермою ефективно та ефективно. Наша платформа спрямована на створення екосистеми, де фермери можуть обмінюватися ідеями, досвідом та ресурсами, щоб спільно працювати над вирішенням складних проблем і підвищенням продуктивності.",
  },
  {
    key: "4",
    text: "Ми маємо чіткий план розвитку нашої платформи, який включає в себе постійне вдосконалення функціоналу та додавання нових можливостей для наших користувачів. Ми також плануємо розширювати нашу платформу шляхом партнерства з іншими компаніями та організаціями, що сприятиме розвитку аграрного сектору в цілому. Наша мета - створити ефективну та доступну платформу, яка допоможе фермерам збільшити врожайність, підвищити ефективність та зробити сільське господарство більш стійким і конкурентоспроможним.",
  },
  {
    key: "5",
    text: "SPEROW відданий своєму завданню підтримки фермерів у досягненні їхніх цілей і впевнений, що разом з нашими користувачами ми зможемо створити майбутнє, де сільське господарство є не лише стійким і ефективним, але й відкритим для інновацій та нових можливостей. Наша місія - зробити кожну ферму успішною, а кожного фермера - впевненим у своєму майбутньому. Давайте разом будувати світле та процвітаюче майбутнє сільського господарства з SPEROW!",
  },
];