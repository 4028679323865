import React, { FC } from "react";
import { Page } from "../../layouts";
import { Badge, Card, CardBody, CardFooter, CardHeader, Col, Container, ListGroup, Row, Stack } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./pricing.module.scss";

interface Props {}

export const Pricing: FC<Props> = (props) => {
  return (
    <Page classNames={{ main: "justify-content-center py-5" }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h1 className="text-center my-0">Підписки</h1>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} md={6} xl={3}>
            <Card className={classes("h-100", styles.card)}>
              <CardHeader className="bg-primary">
                <span className="text-uppercase text-warning fw-bold">Free</span>
              </CardHeader>
              <ListGroup variant="flush" className="border-bottom-0">
                <ListGroup.Item className="text-secondary">✓ Створення 1 огранізації</ListGroup.Item>
                <ListGroup.Item className="text-secondary">
                  ✓ Навчання роботи з платформою та ведення свого бізнесу
                </ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Моніторинг техніки 1 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Робота з персоналом 1 людина</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Планування робіт та завдань</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Моніторинг полів 1 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Спілкування з іншими користувачами</ListGroup.Item>
              </ListGroup>
              <CardBody />
              <CardFooter>
                <span className="fw-bold">0$</span>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={6} xl={3} className="mt-4 mt-md-0">
            <Card className={classes("h-100", styles.card)}>
              <CardHeader>
                <span className="text-uppercase fw-bold">Personal</span>
              </CardHeader>
              <ListGroup variant="flush" className="border-bottom-0">
                <ListGroup.Item className="text-secondary">✓ FREE plan</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Створення 3-х організацій</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Моніторинг техніки 5 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Робота з персоналом до 10 людей</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Моніторинг полів 5 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Складські приміщення 1 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Доступ до партнерської програми</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Функціонал теплиць</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Юридична Підтримка</ListGroup.Item>
              </ListGroup>
              <CardBody />
              <CardFooter>
                <span className="fw-bold">20$</span>
                <span className="text-secondary"> / місяць</span>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={6} xl={3} className="mt-4 mt-xl-0">
            <Card className={classes("h-100", styles.card)}>
              <CardHeader>
                <span className="text-uppercase fw-bold">Business</span>
              </CardHeader>
              <ListGroup variant="flush" className="border-bottom-0">
                <ListGroup.Item className="text-secondary">✓ PERSONAL plan</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Створення 10 організацій</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Моніторинг техніки 50 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Робота з персоналом до 50 людей</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Моніторинг полів 50 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Складські приміщення 5 од.</ListGroup.Item>
                <ListGroup.Item className="text-secondary">✓ Доступ до бази знань</ListGroup.Item>
                <ListGroup.Item className="text-secondary">
                  ✓ Навчання персоналу та підвищення кваліфікації
                </ListGroup.Item>
                <ListGroup.Item className="text-secondary">
                  ✓ Земельний банк
                  <Badge bg="warning" className="text-uppercase ms-2">Обмежений</Badge>
                </ListGroup.Item>
              </ListGroup>
              <CardBody />
              <CardFooter>
                <span className="fw-bold">100$</span>
                <span className="text-secondary"> / місяць</span>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={6} xl={3} className="mt-4 mt-xl-0">
            <Card className={classes("h-100", styles.card)}>
            <CardHeader>
              <Stack direction="horizontal" className="justify-content-between">
                <span className="text-uppercase fw-bold">Ultra</span>
                <Badge bg="info" className="text-uppercase">Без обмежень</Badge>
              </Stack>
            </CardHeader>
              <ListGroup variant="flush" className="border-bottom-0">
                <ListGroup.Item className="text-secondary">∞ BUSINESS plan</ListGroup.Item>
                <ListGroup.Item className="text-secondary">∞ Створення організацій</ListGroup.Item>
                <ListGroup.Item className="text-secondary">∞ Моніторинг техніки</ListGroup.Item>
                <ListGroup.Item className="text-secondary">∞ Робота з персоналом</ListGroup.Item>
                <ListGroup.Item className="text-secondary">∞ Моніторинг полів</ListGroup.Item>
                <ListGroup.Item className="text-secondary">∞ Складські приміщення</ListGroup.Item>
                <ListGroup.Item className="text-secondary">∞ Земельний банк</ListGroup.Item>
              </ListGroup>
              <CardBody />
              <CardFooter>
                <span className="fw-bold">300$</span>
                <span className="text-secondary"> / місяць</span>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};