import { configureStore, Reducer } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { combineReducers } from "redux";
import storage from "redux-persist/es/storage";
import { authorizationReducer } from "./slices/authorization.slice";
import { userReducer } from "./slices/user.slice";

const persistConfig = {
  key: "root",
  blacklist: [ "loader" ],
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  storage,
};

const rootReducer = combineReducers({
  authorization: authorizationReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer as Reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;