// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_container__YxxUC {
  flex: 1 1;
}
.dashboard_container__YxxUC .dashboard_sidebar__awBkj {
  width: 70px;
}
.dashboard_container__YxxUC .dashboard_header__lQC5v, .dashboard_container__YxxUC .dashboard_main__plTq4 {
  width: calc(100% - 70px);
}
.dashboard_container__YxxUC .dashboard_sidebar__awBkj, .dashboard_container__YxxUC .dashboard_header__lQC5v, .dashboard_container__YxxUC .dashboard_main__plTq4 {
  transition: width ease 200ms;
}
.dashboard_container__YxxUC.dashboard_expanded__X7vLs .dashboard_sidebar__awBkj {
  width: 250px;
}
.dashboard_container__YxxUC.dashboard_expanded__X7vLs .dashboard_header__lQC5v, .dashboard_container__YxxUC.dashboard_expanded__X7vLs .dashboard_main__plTq4 {
  width: calc(100% - 250px);
}
.dashboard_container__YxxUC.dashboard_expanded__X7vLs .dashboard_sidebar__awBkj, .dashboard_container__YxxUC.dashboard_expanded__X7vLs .dashboard_header__lQC5v, .dashboard_container__YxxUC.dashboard_expanded__X7vLs .dashboard_main__plTq4 {
  transition: width ease 200ms;
}

.dashboard_sidebar__awBkj {
  height: 100%;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.75);
}

.dashboard_header__lQC5v {
  height: 70px;
}
.dashboard_header__lQC5v .dashboard_back__32GXO {
  width: 16px;
  height: 16px;
}

.dashboard_breadcrumbs__j06zd {
  height: 40px;
}

.dashboard_main__plTq4 {
  height: calc(100% - 70px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/layouts/dashboard/dashboard.module.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;AAHF;AAKE;EACE,WARgB;AAKpB;AAME;EACE,wBAAA;AAJJ;AAOE;EACE,4BAAA;AALJ;AASI;EACE,YApBc;AAapB;AAUI;EACE,yBAAA;AARN;AAWI;EACE,4BAAA;AATN;;AAcA;EACE,YAAA;EACA,0CAAA;AAXF;;AAcA;EACE,YAtCc;AA2BhB;AAaE;EACE,WAAA;EACA,YAAA;AAXJ;;AAeA;EACE,YAAA;AAZF;;AAeA;EACE,yBAAA;EACA,gBAAA;AAZF","sourcesContent":["$sidebar-width-min: 70px;\n$sidebar-width-max: 250px;\n$header-height: 70px;\n\n.container {\n  flex: 1;\n\n  .sidebar {\n    width: $sidebar-width-min;\n  }\n\n  .header, .main {\n    width: calc(100% - $sidebar-width-min);\n  }\n\n  .sidebar, .header, .main {\n    transition: width ease 200ms;\n  }\n\n  &.expanded {\n    .sidebar {\n      width: $sidebar-width-max;\n    }\n\n    .header, .main {\n      width: calc(100% - $sidebar-width-max);\n    }\n\n    .sidebar, .header, .main {\n      transition: width ease 200ms;\n    }\n  }\n}\n\n.sidebar {\n  height: 100%;\n  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.75);\n}\n\n.header {\n  height: $header-height;\n\n  .back {\n    width: 16px;\n    height: 16px;\n  }\n}\n\n.breadcrumbs {\n  height: 40px;\n}\n\n.main {\n  height: calc(100% - $header-height);\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dashboard_container__YxxUC`,
	"sidebar": `dashboard_sidebar__awBkj`,
	"header": `dashboard_header__lQC5v`,
	"main": `dashboard_main__plTq4`,
	"expanded": `dashboard_expanded__X7vLs`,
	"back": `dashboard_back__32GXO`,
	"breadcrumbs": `dashboard_breadcrumbs__j06zd`
};
export default ___CSS_LOADER_EXPORT___;
