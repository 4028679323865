// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, div#root {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
}

div#root {
  min-width: 360px;
  scale: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  text-indent: 1em;
}

input {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,8JAAA;EAEA,mCAAA;EACA,kCAAA;AAAF;;AAGA;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,oBAAA;AAAF;;AAGA;EACE,+EAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n  \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nhtml, body, div#root {\n  width: 100%;\n  max-width: 100vw;\n  min-height: 100vh;\n}\n\ndiv#root {\n  min-width: 360px;\n  scale: none;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: start;\n  align-items: stretch;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\np {\n  text-indent: 1em;\n}\n\ninput {\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
