import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useStore } from "./use-store";
import { User, USER_ROLE, UserGender, UserRole } from "../models";
import {
  setUser,
  resetUser,
  setUserCustomerId,
  setUserDob,
  setUserEmail,
  setUserGender,
  setUserId,
  setUserIsActivated,
  setUserIsBanned,
  setUserPhone,
  setUserRole,
  setUserFirstName,
  setUserLastName,
  setUserFullName,
} from "../store/slices/user.slice";

export const useUser = () => {
  const dispatch = useDispatch();
  const {
    id,
    email,
    firstName,
    lastName,
    fullName,
    role,
    phone,
    gender,
    dob,
    customerId,
    isActivated,
    isBanned,
  } = useStore<User>((state) => state.user);

  const set = (value: User): void => {
    dispatch(setUser(value));
  };

  const reset = (): void => {
    dispatch(resetUser());
  };

  const setId = (value: string): void => {
    dispatch(setUserId(value))
  };

  const resetId = (): void => {
    dispatch(setUserId(""));
  };

  const setEmail = (value: string): void => {
    dispatch(setUserEmail(value));
  };

  const resetEmail = (): void => {
    dispatch(setUserEmail(""));
  };

  const setFirstName = (value: string): void => {
    dispatch(setUserFirstName(value));
  };

  const resetFirstName = (): void => {
    dispatch(setUserFirstName(""));
  };

  const setLastName = (value: string): void => {
    dispatch(setUserLastName(value));
  };

  const resetLastName = (): void => {
    dispatch(setUserLastName(""));
  };

  const setFullName = (value: string): void => {
    dispatch(setUserFullName(value));
  };

  const resetFullName = (): void => {
    dispatch(setUserFullName(""));
  };

  const setRole = (value: UserRole): void => {
    dispatch(setUserRole(value));
  };

  const setPhone = (value: string): void => {
    dispatch(setUserPhone(value));
  };

  const resetPhone = (): void => {
    dispatch(setUserPhone(""));
  };

  const resetRole = (): void => {
    dispatch(setUserRole(USER_ROLE.USER));
  };

  const setGender = (value: "" | UserGender): void => {
    dispatch(setUserGender(value));
  };

  const resetGender = (): void => {
    dispatch(setUserGender(""));
  };

  const setDob = (value: number): void => {
    dispatch(setUserDob(value));
  };

  const resetDob = (): void => {
    dispatch(setUserDob(0));
  };

  const setCustomerId = (value: string): void => {
    dispatch(setUserCustomerId(value));
  };

  const resetCustomerId = (): void => {
    dispatch(setUserCustomerId(""));
  };

  const setIsActivated = (value: boolean): void => {
    dispatch(setUserIsActivated(value));
  };

  const resetIsActivated = (): void => {
    dispatch(setUserIsActivated(false));
  };

  const setIsBanned = (value: boolean): void => {
    dispatch(setUserIsBanned(value));
  };

  const resetIsBanned = (): void => {
    dispatch(setUserIsBanned(false));
  };

  const isUser = useCallback(() => {
    return role === USER_ROLE.USER;
  }, [ role ]);

  const isAdmin = useCallback(() => {
    return role === USER_ROLE.ADMIN;
  }, [ role ]);

  return {
    set,
    reset,
    id,
    setId,
    resetId,
    email,
    setEmail,
    resetEmail,
    firstName,
    setFirstName,
    resetFirstName,
    lastName,
    setLastName,
    resetLastName,
    fullName,
    setFullName,
    resetFullName,
    role,
    setRole,
    resetRole,
    phone,
    setPhone,
    resetPhone,
    gender,
    setGender,
    resetGender,
    dob,
    setDob,
    resetDob,
    customerId,
    setCustomerId,
    resetCustomerId,
    isActivated,
    setIsActivated,
    resetIsActivated,
    isBanned,
    setIsBanned,
    resetIsBanned,
    isUser,
    isAdmin,
  };
};