// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_welcome__oFH41 {
  height: 700px;
}

.home_bg__h0YPf {
  object-fit: cover;
  object-position: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,uBAAA;AACF","sourcesContent":[".welcome {\n  height: 700px;\n}\n\n.bg {\n  object-fit: cover;\n  object-position: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcome": `home_welcome__oFH41`,
	"bg": `home_bg__h0YPf`
};
export default ___CSS_LOADER_EXPORT___;
