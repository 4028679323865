import React, { FC, PropsWithChildren, useMemo, useState } from "react";
import { LoaderContext } from "./loader.context";
import crypto from "crypto-js";
import { classes } from "../../utils";
import styles from "./loader.module.scss";

interface Props extends PropsWithChildren {}

export const LoaderProvider: FC<Props> = ({ children }) => {
  const [ pull, setPull ] = useState<Array<{ key: string, label?: string, isActive: boolean, startedAt: number }>>([]);

  const generateUniqueKey = (): string => {
    const key: string = crypto.lib.WordArray.random(16).toString();
    const isFound: boolean = !!pull.find((task) => task.key === key);

    if (isFound) {
      return generateUniqueKey();
    }

    return key;
  };

  const create = (label?: string): { key: string, label?: string, start: () => void, stop: () => void } => {
    const key: string = generateUniqueKey();

    setPull((prevState) => [ ...prevState, { key, label, isActive: false, startedAt: 0 } ]);

    return {
      key,
      label,
      start: (cb?: () => void) => start({ key, label, cb }),
      stop: (cb?: () => void) => stop({ key, label, cb }),
    };
  };

  const start = (task: { key: string, label?: string, cb?: () => void }): void => {
    setPull((prevState) => {
      const isFound: boolean = !!prevState.find((item) => item.key === task.key);

      if (isFound) {
        if (!!task.cb) {
          const timeout = setTimeout(() => {
            !!task?.cb && task?.cb();
            clearTimeout(timeout);
          }, 400);
        }

        return [
          ...prevState.filter((item) => item.key !== task.key),
          { ...task, isActive: true, startedAt: (new Date()).getTime() },
        ];
      }

      if (!!task.cb) {
        const timeout = setTimeout(() => {
          !!task?.cb && task?.cb();
          clearTimeout(timeout);
        }, 400);
      }

      return prevState;
    });
  };

  const stop = (task: { key: string, label?: string, cb?: () => void }): void => {
    setPull((prevState) => {
      const _task = prevState.find((item) => item.key === task.key);

      if (!!_task) {
        const now = (new Date()).getTime();
        const duration = now - _task.startedAt;

        if (duration >= 1000) {
          if (!!task.cb) {
            const timeout = setTimeout(() => {
              !!task?.cb && task?.cb();
              clearTimeout(timeout);
            }, 400);
          }

          return prevState.filter((item) => item.key !== task.key);
        }

        const timeout = setTimeout(() => {
          stop(task);
          clearTimeout(timeout);
        }, 1000 - duration);

        return prevState;
      }

      return prevState;
    });
  };

  const reset = (): void => {
    setPull([]);
  };

  const visible = useMemo<boolean>(() => !!pull.filter(({ isActive }) => isActive).length, [ pull ]);

  return (
    <LoaderContext.Provider value={{ visible, create, start, stop, reset }}>
      {children}
      <div
        className={classes(
          styles.container,
          "position-fixed top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center z-3",
          visible && styles.visible,
        )}
      >
        <div className={classes("position-relative", styles.loader)}>
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
          <div className={classes("position-absolute", styles.circle)} />
        </div>
        <span className={classes("fs-6 mt-2 text-white text-center", styles.message)}>
          {visible && pull.filter(({ isActive }) => isActive)[0]?.label}
        </span>
      </div>
    </LoaderContext.Provider>
  );
};
