import axios, { AxiosError, AxiosResponse, AxiosResponseTransformer, Method, RawAxiosRequestHeaders } from "axios";
import { User, UserGender } from "../models";
import { useAuthorization } from "./use-authorization";
import { useUser } from "./use-user";
import { useLoader } from "./use-loader";

interface RequestConfig {
  method: Method;
  url: string;
  headers?: RawAxiosRequestHeaders;
  params?: object;
  data?: object;
  transformResponse?: AxiosResponseTransformer;
}

interface RequestArguments<P, D> {
  params?: P;
  data?: D;
}

interface GETRequestArgs<T> {
  params?: T;
}

interface POSTRequestArgs<T> {
  data?: T;
}

const http = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const useApi = () => {
  const { accessToken, resetAccessToken } = useAuthorization();
  const user = useUser();
  const loader = useLoader();

  const request = <T>({
    method,
    url,
    headers = {},
    params = {},
    data = {},
    transformResponse = undefined,
  }: RequestConfig): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      http.request<any, AxiosResponse<T>>({
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          ...(!!accessToken ? { "Authorization": accessToken } : undefined),
          ...headers,
        },
        params,
        data,
        transformResponse,
      })
        .then(({ data }) => resolve(data))
        .catch((error) => {
          if (error instanceof AxiosError && error?.response?.status === 401) {
            const logout = loader.create("Вихід з системи...");

            logout.start();

            resetAccessToken();
            user.reset();

            logout.stop();
          }

          if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
            return reject({ error, message: "No internet connection.", code: "network_error" });
          }

          reject({ error, message: error.response?.data?.message, code: error.response?.data?.code });
        });
    });
  };

  return {
    authorization: {
      signIn: ({ data, ...config }: POSTRequestArgs<{ email: string, password: string, stay: boolean }>) => {
        return request<{ token: string, user: User }>({
          method: "POST",
          url: "/authorization/sign-in",
          data,
          ...config,
        });
      },
      signUp: ({ data, ...config }: POSTRequestArgs<{ email: string, firstName: string, lastName: string, phone?: string, gender?: "" | UserGender, dob?: number, country: string, language: string, password: string, stay: boolean }>) => {
        return request<{ token: string, user: User }>({
          method: "POST",
          url: "/authorization/sign-up",
          data,
          ...config,
        });
      },
      signOut: ({ data, ...config }: POSTRequestArgs<undefined>) => {
        return request<{ token: string, user: User }>({
          method: "POST",
          url: "/authorization/sign-up",
          data,
          ...config,
        });
      },
    },
    user: {
      get: ({ params, ...config }: GETRequestArgs<User>) => {
        return request<User>({
          method: "GET",
          url: "/user",
          params,
          ...config,
        });
      },
      activate: ({ data, ...config }: POSTRequestArgs<{ code: string }>) => {
        return request<User>({
          method: "POST",
          url: "/user/activate",
          data,
          ...config,
        });
      },
      sendActivationMail: ({ data, ...config }: POSTRequestArgs<undefined>) => {
        return request<{ message: string }>({
          method: "POST",
          url: "/user/activation/send-mail",
          data,
          ...config,
        });
      },
      resetPassword: ({ data, ...config }: POSTRequestArgs<{ email: string, code: string, password: string }>) => {
        return request<{}>({
          method: "POST",
          url: "/user/reset-password",
          data,
          ...config,
        });
      },
      resetPasswordVerify: ({ data, ...config }: POSTRequestArgs<{ email: string, code: string }>) => {
        return request<{}>({
          method: "POST",
          url: "/user/reset-password/verify",
          data,
          ...config,
        });
      },
      sendResetPasswordMail: ({ data, ...config }: POSTRequestArgs<{ email: string }>) => {
        return request<{}>({
          method: "POST",
          url: "/user/reset-password/send-mail",
          data,
          ...config,
        });
      },
    },
  };
};
