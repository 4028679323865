// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_container__bXhDY {
  flex: 1 1;
  padding-top: 70px;
}

.page_main__ZynfY {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/layouts/page/page.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,SAAA;AACF","sourcesContent":[".container {\n  flex: 1;\n  padding-top: 70px;\n}\n\n.main {\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `page_container__bXhDY`,
	"main": `page_main__ZynfY`
};
export default ___CSS_LOADER_EXPORT___;
