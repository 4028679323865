import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthorizationState {
  accessToken: string;
}

const initialState: AuthorizationState = {
  accessToken: "",
};

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setAuthorizationAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    resetAuthorizationAccessToken: (state) => {
      state.accessToken = initialState.accessToken;
    },
  },
});

export const {
  reducer: authorizationReducer,
  actions: {
    setAuthorizationAccessToken,
    resetAuthorizationAccessToken,
  },
} = authorizationSlice;