import React, { FC } from "react";
import { Page } from "../../layouts";
import { Col, Container, Row } from "react-bootstrap";

interface Props {}

export const NotFound: FC<Props> = (props) => {
  return (
    <Page>
      <Container>
        <Row>
          <Col xs={12}>
            <h1>404, Not Found!</h1>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};