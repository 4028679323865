import { About } from "./about";
import { Activation } from "./activation";
import { Dashboard } from "./dashboard";
import { Home } from "./home";
import { Main } from "./main";
import { NotAuthorized } from "./not-authorized";
import { NotFound } from "./not-found";
import { Pricing } from "./pricing";
import { ResetPassword } from "./reset-password";
import { SignIn } from "./sign-in";
import { SignUp } from "./sign-up";

export {
  About,
  Activation,
  Dashboard,
  Home,
  Main,
  NotAuthorized,
  NotFound,
  Pricing,
  ResetPassword,
  SignIn,
  SignUp,
};