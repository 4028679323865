import React, { FC } from "react";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../../store";
import { LoaderProvider } from "../loader";
import { Router } from "../router";
import { NotificationProvider } from "../notification";

interface Props {}

export const Root: FC<Props> = (props) => {
  return (
    <StoreProvider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <LoaderProvider>
          <NotificationProvider>
            <Router />
          </NotificationProvider>
        </LoaderProvider>
      </PersistGate>
    </StoreProvider>
  );
};