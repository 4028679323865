import { useMemo } from "react";
import { useStore } from "./use-store";
import { useDispatch } from "react-redux";
import { resetAuthorizationAccessToken, setAuthorizationAccessToken } from "../store/slices/authorization.slice";

export const useAuthorization = () => {
  const dispatch = useDispatch();
  const { accessToken } = useStore((state) => state.authorization);

  const isAuthorized = useMemo((): boolean => {
    return !!accessToken;
  }, [ accessToken ]);

  const setAccessToken = (value: string): void => {
    dispatch(setAuthorizationAccessToken(value));
  };

  const resetAccessToken = (): void => {
    dispatch(resetAuthorizationAccessToken());
  };

  return { accessToken, setAccessToken, resetAccessToken, isAuthorized };
};