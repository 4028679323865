import React, { FC, PropsWithChildren, useState } from "react";
import { NotificationContext } from "./notification.context";
import { ToastContainer } from "react-bootstrap";
import { Notification } from "./notification.component";
import crypto from "crypto-js";

interface Props extends PropsWithChildren {}

interface NotificationObject {
  key: string;
  type: "message" | "success" | "info" | "warning" | "error";
  title?: string;
  body?: React.ReactNode;
}

export const NotificationProvider: FC<Props> = ({ children }) => {
  const [ list, setList ] = useState<NotificationObject[]>([]);

  const generateUniqueKey: () => string = () => {
    const key: string = crypto.lib.WordArray.random(16).toString();
    const isFound: boolean = !!list.find((item) => item.key === key);

    if (isFound) {
      return generateUniqueKey();
    }

    return key;
  };

  const onClose = (key: string) => {
    setList((prevState) => prevState.filter((item) => item.key !== key));
  };

  const show = ({ type, title, body, visible = 5000 }: {
    type: NotificationObject["type"],
    title?: string,
    body?: React.ReactNode,
    visible?: number,
  }): void => {
    const key = generateUniqueKey();

    setList((prevState) => {
      return [ ...prevState, { key, type, title, body } ];
    });

    if (!!visible) {
      const timeout = setTimeout(() => {
        setList((prevState) => prevState.filter((item) => item.key !== key));

        clearTimeout(timeout);
      }, visible);
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        message: ({ title, body, visible }) => show({ type: "message", title, body, visible }),
        success: ({ title, body, visible }) => show({ type: "success", title, body, visible }),
        info: ({ title, body, visible }) => show({ type: "info", title, body, visible }),
        warning: ({ title, body, visible }) => show({ type: "warning", title, body, visible }),
        error: ({ title, body, visible }) => show({ type: "error", title, body, visible }),
      }}
    >
      {children}
      <ToastContainer position="bottom-end" className="position-fixed p-3 z-2">
        {list.map(({ key, type, title, body }) => (
          <Notification key={key} type={type} title={title} body={body} onClose={() => onClose(key)} />
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  );
};