import React, { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { About, Dashboard, Home, Main, NotFound, Pricing, ResetPassword, SignIn, SignUp } from "../../pages";
import { withRequireOptions } from "../../hocs";

interface Props {}

const MainWithRequireOptions = withRequireOptions(Main, { isAuthorized: true, isActivated: true });
const DashboardWithRequireOptions = withRequireOptions(Dashboard, { isAuthorized: true, isActivated: true });

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/sign-in", element: <SignIn /> },
  { path: "/sign-up", element: <SignUp /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/main", element: <MainWithRequireOptions /> },
  { path: "/dashboard", element: <DashboardWithRequireOptions /> },
  { path: "/about", element: <About /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "*", element: <NotFound /> },
]);

export const Router: FC<Props> = (props) => {
  return <RouterProvider router={router} />;
};