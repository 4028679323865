import React, { FC } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Page } from "../../layouts";
import { useApi, useAuthorization, useLoader, useNotification, useUser } from "../../hooks";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ERROR, errorBy, REGEXP } from "../../utils";
import { INPUT_LENGTH } from "./sign-in.data";

interface Props {}

interface FormData {
  email: string;
  password: string;
  stay: boolean;
}

export const SignIn: FC<Props> = (props) => {
  const api = useApi();
  const navigate = useNavigate();
  const authorization = useAuthorization();
  const user = useUser();
  const loader = useLoader();
  const notification = useNotification();

  const { register, reset, formState: { errors, isValid }, setError, handleSubmit } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
      stay: false,
    },
  });

  const onSubmit = (data: FormData): void => {
    const task = loader.create("Зачекайте, будь ласка, обробляємо запит...");

    task.start();

    api.authorization.signIn({ data })
      .then(({ token, user: data }) => {
        authorization.setAccessToken(token);
        user.set(data);
        reset();
        navigate("/main");
        task.stop();
      })
      .catch(({ message, code }) => {
        if (code === ERROR.USER_NOT_FOUND) {
          setError("email", { message: errorBy(code) });
        }

        if (code === ERROR.INVALID_PASSWORD) {
          setError("password", { message: errorBy(code) });
        }

        task.stop(() => {
          notification.error({ body: errorBy(code) || message });
        });
      });
  };

  return (
    <Page classNames={{ main: "justify-content-center align-items-center py-5" }}>
      <Container>
        <Row>
          <Col
            xs={12}
            sm={10}
            md={8}
            lg={6}
            xxl={4}
            className="offset-0 offset-sm-1 offset-md-2 offset-lg-3 offset-xxl-4"
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h1 className="fw-bold text-center my-0">Авторизація</h1>
              <Form.Group className="mt-3" controlId="formBasicEmail">
                <Form.Label>Ваш email:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-mail..."
                  minLength={INPUT_LENGTH.EMAIL.MIN}
                  maxLength={INPUT_LENGTH.EMAIL.MAX}
                  className={!!errors?.email ? "border-danger" : ""}
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Це поле є обов'язковим для введення.",
                    },
                    minLength: {
                      value: INPUT_LENGTH.EMAIL.MIN,
                      message: `Мінімальна довжина має становити ${INPUT_LENGTH.EMAIL.MIN} символів.`,
                    },
                    maxLength: {
                      value: INPUT_LENGTH.EMAIL.MAX,
                      message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.EMAIL.MAX} символів.`,
                    },
                    validate: (value) => !!value.match(REGEXP.EMAIL) || "Це не схоже на емейл.",
                  })}
                />
                {!!errors?.email ? (
                  <Form.Text className="text-danger">{errors.email.message}</Form.Text>
                ) : (
                  <Form.Text className="text-muted">Ми ніколи нікому не передамо вашу електронну адресу.</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mt-3" controlId="formBasicPassword">
                <Form.Label>Ваш пароль:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Пароль..."
                  minLength={INPUT_LENGTH.PASSWORD.MIN}
                  maxLength={INPUT_LENGTH.PASSWORD.MAX}
                  className={!!errors?.password ? "border-danger" : ""}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Це поле є обов'язковим для введення.",
                    },
                    minLength: {
                      value: INPUT_LENGTH.PASSWORD.MIN,
                      message: `Мінімальна довжина має становити ${INPUT_LENGTH.PASSWORD.MIN} символів.`,
                    },
                    maxLength: {
                      value: INPUT_LENGTH.PASSWORD.MAX,
                      message: `Максимальна довжина має бути не більше ${INPUT_LENGTH.PASSWORD.MAX} символів.`,
                    },
                  })}
                />
                {!!errors?.password ? (
                  <Form.Text className="text-danger">{errors.password.message}</Form.Text>
                ) : (
                  <Form.Text className="text-muted">Ми та ніхто інший ніколи не дізнається ваш пароль.</Form.Text>
                )}
              </Form.Group>
              <Stack direction="horizontal" className="justify-content-between align-items-center mt-3">
                <Form.Check type="checkbox" id="sign-in_stay" label="Залишатись в системі" {...register("stay")} />
                <Link to="/reset-password">Забули пароль?</Link>
              </Stack>
              <Stack direction="vertical" className="justify-content-center align-items-center gap-3 mt-3">
                <Button variant="primary" type="submit" disabled={!isValid}>Увійти</Button>
                <span className="fs-6 text-center text-secondary">
                  У вас немає облікового запису? Спробуйте <Link to="/sign-up">зареєструватися</Link>!
                </span>
              </Stack>
            </Form>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};