import React, { FC } from "react";
import { useAuthorization, useUser } from "../hooks";
import { Activation, NotAuthorized } from "../pages";

interface Options {
  isAuthorized?: boolean;
  isActivated?: boolean;
}

export const withRequireOptions = (Component: FC, { isAuthorized = false, isActivated = false }: Options): FC => {
  return (props) => {
    const authorization = useAuthorization();
    const user = useUser();

    if (isAuthorized && !authorization.isAuthorized) {
      return <NotAuthorized />;
    }

    if (isAuthorized && isActivated && authorization.isAuthorized && !user.isActivated) {
      return <Activation />;
    }

    return <Component {...props} />
  };
};