import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Page } from "../../layouts";
import { PARAGRAPHS } from "./about.data";

interface Props {}

export const About: FC<Props> = (props) => {
  return (
    <Page classNames={{ main: "py-5" }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h1 className="text-center mt-0 mb-2">Про нас</h1>
            {PARAGRAPHS.map(({ key, text }, index) => (
              <p key={key} className={index === 0 ? "mt-5" : "mt-3"}>{text}</p>
            ))}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};