import React, { FC } from "react";
import { Dashboard } from "../../layouts";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import { useAuthorization, useUser } from "../../hooks";

interface Props {}

export const Main: FC<Props> = (props) => {
  const user = useUser();
  const { accessToken } = useAuthorization();

  return (
    <Dashboard>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={3}>
            <div className="bg-primary" style={{ height: 50 }}></div>
          </Col>
          <Col xs={3}>
            <div className="bg-primary" style={{ height: 50 }}></div>
          </Col>
          <Col xs={3}>
            <div className="bg-primary" style={{ height: 50 }}></div>
          </Col>
          <Col xs={3}>
            <div className="bg-primary" style={{ height: 50 }}></div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={9}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={6}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={6}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={4}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h1 className="text-center my-0">Account page</h1>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <span>User:</span>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <ul className="d-flex flex-column gap-2 m-0">
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">ID:</span>
                  <span>{user.id}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Email:</span>
                  <span>{user.email}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">First Name:</span>
                  <span>{user.firstName}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Last Name:</span>
                  <span>{user.lastName}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Full Name:</span>
                  <span>{user.fullName}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Role:</span>
                  <span>{user.role}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Phone:</span>
                  <span>{user.phone || "NONE"}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Gender:</span>
                  <span>{user.gender || "NONE"}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Date of birthday:</span>
                  <span>{!!user.dob ? (new Date(user.dob)).toString() : "NONE"}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Customer ID:</span>
                  <span>{!!user.customerId || "NONE"}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Is Activated:</span>
                  <span>{user.isActivated ? "Yes" : "No"}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="horizontal" className="flex-wrap gap-2">
                  <span className="fw-bold">Is Banned:</span>
                  <span>{user.isBanned ? "Yes" : "No"}</span>
                </Stack>
              </li>
              <li>
                <Stack direction="vertical" className="gap-2">
                  <span className="fw-bold">Access token:</span>
                  <textarea
                    className="w-100 text-secondary"
                    style={{ resize: "none" }}
                    rows={3}
                    value={accessToken}
                    readOnly
                  />
                </Stack>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={4}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>Body</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};