import React, { FC, PropsWithChildren } from "react";
import { Footer, Header } from "../../components";
import { classes } from "../../utils";
import styles from "./page.module.scss";

interface Props extends PropsWithChildren {
  classNames?: {
    container?: string;
    header?: string;
    main?: string;
    footer?: string;
  };
}

export const Page: FC<Props> = ({ classNames, children }) => {
  return (
    <div
      className={classes(
        "position-relative d-flex flex-column justify-content-start align-items-stretch",
        styles.container,
        classNames?.container,
      )}
    >
      <Header className={classes("position-fixed top-0 start-0 z-1", classNames?.header)} />
      <main
        className={classes(
          "w-100 d-flex flex-column justify-content-start align-items-stretch z-0",
          styles.main,
          classNames?.main,
        )}
      >{children}</main>
      <Footer className={classes("z-0", classNames?.footer)} />
    </div>
  );
};