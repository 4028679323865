import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

interface Props {}

export const Preview: FC<Props> = (props) => {
  return (
    <Container className="position-fixed h-100 bg-primary" fluid>
      <Row className="h-100">
        <Col xs={12} className="h-100 d-flex justify-content-center align-items-center">
          <svg width="100" height="auto" viewBox="0 0 844 780" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M360.006 577.339C338.96 568.699 316.477 564.047 293.715 563.624C286.806 563.495 280.057 563.368 273.448 564.364L272.323 564.343C272.177 572.794 272.61 581.246 273.619 589.639C278.963 633.52 300.066 674.004 333.038 703.63C366.011 733.256 408.632 750.028 453.047 750.855C460.404 751.05 467.766 750.759 475.085 749.987C475.642 713.021 464.949 676.75 444.41 645.937C423.872 615.125 394.45 591.212 360.006 577.339Z"
              fill="#ffbc36"
            />
            <path
              d="M456.956 634.82C480.376 664.711 492.733 701.816 491.92 739.816C491.876 742.208 491.837 744.281 491.795 746.514C514.865 741.421 536.583 731.442 555.49 717.246C574.397 703.049 590.057 684.964 601.42 664.204C612.782 643.444 619.584 620.489 621.369 596.878C623.154 573.268 619.881 549.548 611.769 527.31C578.153 527.467 545.372 537.822 517.738 557.012C490.105 576.202 468.914 603.328 456.956 634.82Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M435.524 327.69C322.528 277.808 218.572 209.792 127.852 126.358C84.6903 88.0066 45.2795 45.6994 10.1443 0C10.9851 40.7013 20.5058 80.7658 38.08 117.559C108.528 263.155 295.995 331.776 440.232 363.14C439.527 351.214 437.488 338.06 435.524 327.69Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M438.507 387.446C291.915 353.043 155.412 284.686 40.019 187.838C82.6443 365.853 275.628 411.783 428.698 419.044C433.726 408.381 436.796 397.746 438.507 387.446Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M417.914 434.933C304.222 426.86 193.224 396.259 91.2914 344.882C146.647 454.152 258.86 483.023 368.976 480.676C388.564 468.23 402.425 455.183 412.281 442.653C414.297 440.09 416.172 437.516 417.914 434.933Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M348.94 493.564C283.243 491.374 218.224 478.874 156.212 456.46C183.651 497.35 220.807 519.571 261.815 529.494L322.556 505.381C332.119 501.584 340.891 497.631 348.94 493.564Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M245.212 584.27C245.444 587.203 245.737 590.131 246.092 593.053L246.094 593.076L246.097 593.098C246.492 596.31 246.961 599.505 247.503 602.683L154.988 655.747L173.797 667.44L145.511 686.927L117.808 676.822L0 740.481V687.649L245.212 584.27Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M249.812 618.641C251.355 624.831 253.174 630.936 255.26 636.937L103.638 779.136H31.0854L249.812 618.641Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M263.385 653.247C266.508 660.213 270.011 667.008 273.877 673.601L205.166 779.027H144.039L263.385 653.247Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M594.414 192.443C555.202 189.022 516.069 199.822 484.251 222.845C519.515 169.279 555.653 157.262 556.673 157.407C500.134 81.2559 441.265 74.5963 410.955 85.02C380.646 95.4438 379.043 171.885 379.043 171.885C445.782 278.294 575.033 233.559 575.033 233.559C597.814 441.34 436.509 535.011 389.018 557.896C383.002 554.796 376.823 551.972 370.498 549.437C360.964 545.554 351.176 542.37 341.216 539.903C540.917 455.388 461.665 287.56 461.665 287.56C428.345 288.118 395.437 280.188 366.077 264.526C336.717 248.863 311.883 225.991 293.944 198.089C268.152 82.1245 335.765 36.0867 335.765 36.0867C362.356 21.3644 392.113 13.1943 422.539 12.2622C452.965 11.3301 483.173 17.6629 510.626 30.7297C562.502 57.0786 572.993 141.048 572.993 141.048C589.605 152.196 594.414 192.443 594.414 192.443Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M610.252 300.25C697.14 259.79 775.761 201.493 816.051 117.877C833.599 80.984 843.106 40.8113 843.945 0C808.862 45.8229 769.51 88.2444 726.413 126.699C696.542 154.287 665.233 180.193 632.637 204.321L631.408 205.229C630.918 205.591 630.427 205.953 629.936 206.314L633.083 232.706L608.615 230.567L608.704 231.388C611.368 255.736 611.754 278.671 610.252 300.25Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M590.357 393.57C693.478 364.322 785.702 304.776 813.515 188.67C750.884 241.214 681.996 285.316 608.634 320.093C605.182 346.777 598.809 371.229 590.357 393.57Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M541.666 478.777C632.103 470.395 716.878 434.934 762.796 344.882C705.083 373.783 644.484 396.111 582.139 411.555C570.772 436.81 556.819 459.151 541.666 478.777Z"
              fill="#ffbc36"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M532.209 492.057C529.524 495.214 526.813 498.291 524.083 501.29C527.024 502.537 530.045 503.77 533.15 504.986L549.805 511.509C569.598 504.683 590.481 501.093 611.651 500.994L630.157 500.908L634.807 513.656C659.051 501.344 680.821 483.03 698.522 457.005C644.841 476.146 588.894 487.916 532.209 492.057ZM595.299 528.208C594.556 528.286 593.814 528.368 593.073 528.456L593.536 528.637C594.124 528.497 594.712 528.354 595.299 528.208Z"
              fill="#ffbc36"
            />
            <path
              d="M509.6 152.487C508.134 135.378 492.003 102.921 439.212 109.96C469.713 109.96 474.406 127.557 472.939 136.356C458.764 136.845 435.692 140.755 456.809 152.487C477.925 164.218 500.801 157.375 509.6 152.487Z"
              fill="#ffbc36"
            />
          </svg>
        </Col>
      </Row>
    </Container>
  );
};