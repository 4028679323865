import React, { FC, FormEvent, useEffect, useMemo, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./enter-code-form.module.scss";

interface Props {
  initialState?: string;
  button?: string;
  className?: string;
  onInit?: (list: Array<{ ref: React.RefObject<HTMLInputElement>, value: string }>) => void;
  onChange?: (code: string) => void;
  onSubmit?: (code: string) => void;
}

export const EnterCodeForm: FC<Props> = ({ initialState = "", button, className, onInit, onChange, onSubmit }) => {
  const [ list, setList ] = useState<Array<{ ref: React.RefObject<HTMLInputElement>, value: string }>>([
    { ref: React.createRef(), value: initialState[0] || "" },
    { ref: React.createRef(), value: initialState[1] || "" },
    { ref: React.createRef(), value: initialState[2] || "" },
    { ref: React.createRef(), value: initialState[3] || "" },
    { ref: React.createRef(), value: initialState[4] || "" },
    { ref: React.createRef(), value: initialState[5] || "" },
  ]);

  useEffect(() => {
    !!onInit && onInit(list);
  }, []);

  useEffect(() => {
    !!onChange && onChange(list.map(({ value }) => value || " ").join(""));

    if (!list.find(({ value }) => value === "")) {
      list[5].ref.current?.focus();
    }
  }, [ list ]);

  const isValid = useMemo((): boolean => {
    return !list.find(({ value }) => value === "");
  }, [ list ]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    !!onSubmit && onSubmit(list.map(({ value }) => value || " ").join(""));
  };

  return (
    <Form className={classes("d-flex flex-column", className)} onSubmit={handleSubmit}>
      <Stack direction="horizontal" className="justify-content-center gap-2">
        {list.map(({ ref, value }, index) => (
          <input
            key={`code_form_input-${index}`}
            ref={ref}
            type="text"
            value={value}
            autoFocus={index === 0}
            onChange={() => {}}
            className={classes("text-center align-middle fs-1 lh-1 rounded border border-primary", styles.input)}
            minLength={1}
            maxLength={1}
            onKeyDown={(event) => {
              // Keys: 0 1 2 3 4 5 6 7 8 9
              if ([ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57 ].includes(event.keyCode)) {
                setList((prevState) => {
                  prevState[index].value = event.key;

                  return [ ...prevState ];
                });

                if (index < list.length - 1) {
                  list[index + 1].ref.current?.focus();
                } else {
                  list[index].ref.current?.blur();
                }
              }

              // Key: Backspace
              if (event.keyCode === 8) {
                if (list[index].value !== "") {
                  if (index > 0) {
                    if (list[index].ref.current?.selectionEnd === 0) {
                      list[index - 1].ref.current?.focus();
                    }
                  }

                  if (list[index].ref.current?.selectionEnd === 1) {
                    setList((prevState) => {
                      prevState[index].value = "";

                      return [ ...prevState ];
                    });
                  }
                } else {
                  if (index > 0) {
                    if (list[index].ref.current?.selectionEnd === 0) {
                      list[index - 1].ref.current?.focus();
                    }
                  }
                }
              }
            }}
          />
        ))}
      </Stack>
      <Stack direction="horizontal" className="justify-content-center mt-3">
        <Button variant="primary" type="submit" disabled={!isValid}>
          {!!button ? button : "Підтвердити"}
        </Button>
      </Stack>
    </Form>
  );
};